import React from 'react';
import Portfolio from '../components/Portfolio';

export default function Developer() {
  return (
    <div>
      <Portfolio />
    </div>
  );
}

import React from 'react';
import { ContactForm } from '../components';

export default function Contact() {
  return (
    <>
      <ContactForm />
    </>
  );
}
